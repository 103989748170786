html, body, #root, .App, .content {
  height: 100%;
  width: 100%;
  margin: 0;
  min-width: 0;
  min-height: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
}

.chart {
  height: 100%;
  min-width: 0;
  overflow: hidden;
  max-height: 100%;
}

.scrollable {
  overflow-y: auto;
  height: 100%;
}

.recharts-symbols:hover {
  fill: #1677ff;
}